<template>
  <v-container fluid class="mx-4">
    <v-row>
      <v-card v-if="item" width="90%" class="mx-auto mt-6">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="headline">
              {{ item.name }}
            </v-list-item-title>
            <v-list-item-subtitle
              >{{ $t("createdAt") }}:
              {{ item.createdAt | getDisplayDate }}</v-list-item-subtitle
            >
          </v-list-item-content>
          <span class="mr-2">{{ $t("active") }}:</span>
          <v-switch
            dense
            :input-value="item.enabled"
            @change="enabled_change(item)"
          ></v-switch>
        </v-list-item>
        <v-divider></v-divider>
        <v-card-text>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-subtitle>{{
                $t("report_type")
              }}</v-list-item-subtitle>
              <v-list-item-title>
                {{ item.$calculated.$templateName }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="selectedAssetsExtract" two-line>
            <v-list-item-content>
              <v-list-item-subtitle class="caption"
                >{{ $t("assets") }}:</v-list-item-subtitle
              >
              <v-list-item-title class="more-space">{{
                selectedAssetsExtract.groups
              }}</v-list-item-title>
              <v-list-item-title class="more-space">{{
                selectedAssetsExtract.items
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="selectedDriversExtract" two-line>
            <v-list-item-content>
              <v-list-item-subtitle class="caption"
                >{{ $t("Drivers") }}:</v-list-item-subtitle
              >
              <v-list-item-title class="more-space">{{
                selectedDriversExtract.groups
              }}</v-list-item-title>
              <v-list-item-title class="more-space">{{
                selectedDriversExtract.items
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="selectedGeoZonesExtract" two-line>
            <v-list-item-content>
              <v-list-item-subtitle>{{ $t("geoZones") }}:</v-list-item-subtitle>
              <v-list-item-title class="more-space">{{
                selectedGeoZonesExtract.groups
              }}</v-list-item-title>
              <v-list-item-title class="more-space">{{
                selectedGeoZonesExtract.items
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="isTimeFrame" three-line>
            <v-list-item-content>
              <v-list-item-subtitle class="caption"
                >{{ $t("selected_timeframe") }}:</v-list-item-subtitle
              >
              <v-list-item-title
                >{{ $t("timeframe_start") }}:
                <GsReadableTimeFrame
                  :data="
                    item.referenceParameters.inputValues.timeFrame.startDate
                  "
              /></v-list-item-title>
              <v-list-item-title
                >{{ $t("duration") }}:
                <GsReadableTimeFrame
                  :data="item.referenceParameters.inputValues.timeFrame.period"
                  :duration="true"
              /></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <div class="d-flex">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-subtitle>
                  {{ $t("start_date") }}
                </v-list-item-subtitle>
                <v-list-item-title>{{
                  item.startTime | getDisplayDate | empty
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-subtitle>
                  {{ $t("end_date") }}
                </v-list-item-subtitle>
                <v-list-item-title>{{
                  item.endTime | getDisplayDate | empty
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-subtitle>{{ $t("repeats") }}</v-list-item-subtitle>
              <v-list-item-title>
                <GsCronjobToReadable :cronObject="item | cronObject" />
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <div class="d-flex">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-subtitle>{{
                  $t("last_run_at")
                }}</v-list-item-subtitle>
                <v-list-item-title>{{
                  item.lastRun | getDisplayDate | empty
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-subtitle>{{
                  $t("next_run_at")
                }}</v-list-item-subtitle>
                <v-list-item-title>{{
                  item.nextRun | getDisplayDate | empty
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>

          <div class="d-flex">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-subtitle>{{
                  $t("createdAt")
                }}</v-list-item-subtitle>
                <v-list-item-title
                  >{{ item.createdAt | getDisplayDate | empty }} ({{
                    item.$calculated.$createdBy | empty
                  }})</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-subtitle>{{
                  $t("updatedAt")
                }}</v-list-item-subtitle>
                <v-list-item-title
                  >{{ item.updatedAt | getDisplayDate | empty }} ({{
                    item.$calculated.$updatedBy | empty
                  }})</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </div>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle class="caption"
                >{{ $t("email_notifications") }}:</v-list-item-subtitle
              >
              <v-data-table
                :headers="notificationHeaders"
                :items="
                  emailNotificationsExtract(
                    item.referenceParameters.notificationValues,
                    item.$calculated.emailNotificationLocales
                  )
                "
                :footer-props="{
                  itemsPerPageText: $t('rows_per_page'),
                  pageText: '{0}-{1} / {2}',
                }"
                :loading-text="$t('loading_data')"
                :no-data-text="$t('no_data')"
                :no-results-text="$t('no_result')"
                class="pa-0 ma-0"
              >
              </v-data-table>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="py-4 px-8">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-4 my-2"
                @click="deleteTask(item.taskId)"
                v-bind="attrs"
                v-on="on"
                >mdi mdi-delete</v-icon
              >
            </template>
            <span>{{ $t("delete") }}</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-4 my-2"
                @click="editTask(item)"
                v-bind="attrs"
                v-on="on"
                >mdi mdi-pencil</v-icon
              >
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-4 my-2"
                @click="runImmediately(item)"
                v-bind="attrs"
                v-on="on"
                >mdi-checkbox-marked-circle-plus-outline</v-icon
              >
            </template>
            <span>{{ $t("run_immediately") }}</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-4 my-2"
                @click="showSchedulerSimulator(item)"
                v-bind="attrs"
                v-on="on"
                >mdi mdi-clock</v-icon
              >
            </template>
            <span>{{ $t("show_next_runs") }}</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </v-row>
    <!-- run simulator -->
    <v-dialog v-model="schedulerSimulatorVisible" max-width="300px">
      <v-card v-if="selectedItem">
        <v-toolbar color="primary" dark>{{ $t("next_runs_title") }}</v-toolbar>
        <div class="pa-2">
          <GsSchedulerSimulator
            :scheduleConfig="scheduleConfig"
            :taskName="selectedItem.name"
            :compact="false"
          />
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="schedulerSimulatorVisible = false"
            >{{ $t("close") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- edit task -->
    <v-dialog
      v-if="dialogReportCreatorVisible"
      :value="true"
      max-width="880px"
      persistent
    >
      <GsReportCreator
        ref="reportCreatorComponent"
        @close="closeReportCreator"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { i18n } from "@/locales/i18n";

const { callAPI, callBffAPI } = require("ngt-frontend-core").apiOpsBff;
import dayjs from "dayjs";
import GsSchedulerSimulator from "@/components/GsSchedulerSimulator";
import GsCronjobToReadable from "@/components/GsCronjobToReadable";
import GsReportCreator from "@/components/GsReportCreator";
import GsReadableTimeFrame from "@/components/GsReadableTimeFrame.vue";

export default {
  name: "ScheduledReportDetails",
  components: {
    GsReadableTimeFrame,
    GsSchedulerSimulator,
    GsCronjobToReadable,
    GsReportCreator,
  },
  data() {
    return {
      item: null,
      schedulerSimulatorVisible: false,
      dialogReportCreatorVisible: false,
      scheduleConfig: null,
      notificationHeaders: [
        { text: this.$t("name"), value: "name", align: "start" },
        { text: this.$t("email"), value: "addressTo", align: "start" },
        { text: this.$t("language"), value: "locale", align: "start" },
        {
          text: this.$t("attach-reports"),
          value: "withAttachmentsText",
          align: "start",
        },
      ],
      selectedItem: null,
    };
  },
  computed: {
    selectedAssetsExtract() {
      return this.selectedAssetsAndItemsExtract(this.item.referenceParameters);
    },
    selectedDriversExtract() {
      return this.selectedDriversAndItemsExtract(this.item.referenceParameters);
    },
    selectedGeoZonesExtract() {
      return this.selectedGeoZonesAndItemsExtract(
        this.item.referenceParameters
      );
    },
    isTimeFrame() {
      return !!this.item.referenceParameters?.timeFrame;
    },
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(value) {
        this.getReport(value.params.taskId);
      },
    },
  },
  async mounted() {
    this.dialogReportCreatorVisible = false;
  },
  methods: {
    async getReport(taskId) {
      this.$store.state.loading = true;
      try {
        let url = `/tasks?taskId=${taskId}&r8sFields=createdBy.name,updatedBy.name`;

        const result = await callBffAPI({ url, method: "GET" });
        const item = result.data[0];
        item.$calculated = item.$calculated || {};
        // console.log(this.item)
        await this.calculateProperties(item);
        this.item = item;
        // console.log(this.item)
        this.$store.state.loading = false;
      } catch (error) {
        this.$store.state.loading = false;
        this.errorSnackbar(error);
      }
    },
    async calculateProperties(item) {
      item.$calculated = item.$calculated || {};
      item.$calculated.$template = await this.getTemplate(
        item.referenceParameters.templateId
      );
      item.$calculated.$templateName = i18n.t(
        `template_name.${item.$calculated.$template.name}`
      );
      // item.$calculated.$templateName = await this.getTemplateName(
      //   item.referenceParameters.templateId
      // );
      item.$calculated.emailNotificationLocales =
        await this.GetEmailNotificationLocales(
          item.referenceParameters.templateId
        );
      item.$calculated.$createdBy = item?.createdBy?.name || "";
      item.$calculated.$updatedBy = item?.updatedBy?.name || "";
      return item;
    },
    async enabled_change(item) {
      item.enabled = !item.enabled;
      await this.updateTask(item.taskId, { enabled: item.enabled });
    },
    async updateTask(taskId, data) {
      try {
        this.$store.state.loading = true;
        const url = `/tasks/${taskId}`;
        await callBffAPI({
          url,
          method: "PATCH",
          data,
        });
        this.$store.state.loading = false;
      } catch (error) {
        this.$store.state.loading = false;
        this.errorSnackbar(error);
      }
    },
    async editTask(task) {
      this.dialogReportCreatorVisible = true;
      this.$nextTick(async () => {
        if (this.$refs.reportCreatorComponent) {
          await this.$refs.reportCreatorComponent.edit(task);
        }
      });
    },
    async closeReportCreator() {
      this.dialogReportCreatorVisible = false;
      await this.getReport(this.item.taskId);
    },
    async deleteTask(taskId) {
      const result = confirm(this.$t("delete_modal"));
      if (!result) {
        return;
      }

      try {
        const url = `/tasks/${taskId}`;
        const result = await callBffAPI({ url, method: "DELETE" });
        if (result.status === 204) {
          this.$router.go(-1);
        }
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    showSchedulerSimulator(item) {
      this.selectedItem = item;
      this.schedulerSimulatorVisible = true;
      this.scheduleConfig = {
        schedulingStartTime: dayjs(item.startTime).toISOString(),
        schedulingEndTime: item.endTime,
        schedulingTimeData: {
          hour: item.hour,
          minute: item.minute,
          month: item.month,
          day: item.day,
          dayType: item.dayType,
        },
        timezone: item.timezone,
      };
    },
    async runImmediately(item) {
      if (!item.taskId) {
        return;
      }

      try {
        const url = `${process.env.VUE_APP_BFF_ORIGIN}/tasks/${item.taskId}/run-immediately`;
        const result = await callAPI({ url, method: "POST" });
        if (result?.data?.jobId) {
          this.$store.state.snackbar = {
            text: this.$t("run_immediately_success", [item.name]),
            color: "success",
            timeout: 5000,
            open: true,
            link: result.data.jobId
              ? `/jobs/list/job/${result.data.jobId}`
              : undefined,
            linkLabel: result.data.jobId
              ? this.$t("report_created_success_link_label")
              : undefined,
          };
        } else {
          this.errorToRunImmediate(item.name);
        }
      } catch {
        this.errorToRunImmediate(item.name);
      }
    },
  },
};
</script>
